import React from "react"

const SmallBanner = ({ image, titleFirstPart, titleSecondPart }) => (
    <section 
        className="small-banner" 
        style={{ backgroundImage: `url(${image})`, }}
    >
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <h1 className="small-banner__title">
                        <span className="title__first-word">
                            { titleFirstPart }
                        </span>
                        { titleSecondPart }
                    </h1>
                </div>
            </div>
        </div>
    </section>
)

export default SmallBanner
