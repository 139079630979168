import React from "react"
import { Link } from "gatsby";

import Layout from "../components/layout"
import SEO from "../components/seo"
import SmallBanner from "../components/smallBanner"

const NotFoundPage = () => (
	<Layout>
		<SEO title="404: nie znaleziono" />
		<SmallBanner 
			image={require('../assets/img/ContactBanner.jpg')} 
			titleFirstPart="404" 
		/>
		<section className="not-found">
			<div className="container">
				<div className="row">
					<div className="col-12 not-found__holder">
						<p className="not-found__content">
							Podana strona nie istnieje wróc do <Link to="/">Strony głównej</Link>
						</p>
					</div>
				</div>
			</div>
		</section>

	</Layout>
)

export default NotFoundPage
